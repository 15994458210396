class Datastore {

	constructor () {
		this.state = {}
		this.elements = []
		this.medias = []
	}

	pageIsLoaded(){

		if (Object.keys(this.state).length === 0){
			return false
		}else {
			return true
		}	
	}

	getPage(){	
		return this.state;
	}

	setPage(content){
		this.state = content;
	}

	indexElement(elementContent){
		this.elements.push(elementContent)

		let indexElement = this.elements.length
		return (indexElement - 1)
	}

	indexMedia(mediaContent){
		this.medias.push(mediaContent)

		let indexMedia = this.medias.length
		return (indexMedia - 1)
	}

}


export default {
	init: () => {
		return new Datastore()
	}
}


