import { isMobileOnly, isBrowser, isMobile, isTablet, isIOS, deviceDetect} from 'mobile-device-detect';
// https://github.com/duskload/mobile-device-detect#readme

class Client {

	constructor(){

		this.state = {
			device: '',
			format: '',
			scale: '',
			ratio: '',	
			isInit: false,
			infoMainIsDisplay: true
		}

		this.breakPoint = {
			width: 1.34,
			height: 2.2
		}

		this.mobileMaxWidth = 650 // prob avec ipad 
		this.tabletMaxWidth = 1024
		
		this.desktopWidth = {
			medium: 1450,
			big: 1880,
			max: 2200,
		}

		this.isInit = false
		this.listenerActiv = false
		this.windowListener = this.windowListener.bind(this);
		this.windowListener()
	}

	setListener(){
		if (!(this.listenerActiv)){
			window.addEventListener('resize', this.windowListener)
			this.listenerActiv = true
		}
	}

	isListenerActiv(){
		return this.listenerActiv
	}

	removeListener(){
		if (this.listenerActiv){
			window.removeEventListener('resize', this.windowListener)
			this.listenerActiv = false
		}
	}

	windowListener(){

		isMobileOnly, isBrowser

		if ( isMobile || isTablet){

			if (isMobile){
				this.state.device = 'mobile'
			}
			if (isTablet){
				this.state.device = 'tablet'
			}

		} else { // desktop - if (isBrowser)
			this.state.device = 'desktop'
		}

		// WINDOW_WIDTH

		let windowWidth = window.innerWidth
		let mobileWidth = windowWidth <= this.mobileMaxWidth
		let tabletWidth = windowWidth > this.mobileMaxWidth && windowWidth <= this.tabletMaxWidth
		let deskstopSmall = windowWidth > this.tabletMaxWidth && windowWidth <= this.desktopWidth.medium
		let desktopMed = windowWidth > this.desktopWidth.medium && windowWidth <= this.desktopWidth.big
		let desktopBig = windowWidth > this.desktopWidth.big && windowWidth <= this.desktopWidth.max
		let desktopMax = windowWidth > this.desktopWidth.max

		if (mobileWidth){ this.state.scale = 'mobileWidth' }
		if (tabletWidth){ this.state.scale = 'tabletWidth' }
		if (deskstopSmall){ this.state.scale = 'deskstopSmall' }	
		if (desktopMed){ this.state.scale = 'desktopMed' }	
		if (desktopBig){ this.state.scale = 'desktopBig'  }
		if (desktopMax){ this.state.scale = 'desktopMax' }


		// FORMAT & RATIO

		this.state.resolution = { height: window.innerHeight, width: window.innerWidth }
		this.state.ratio = this.state.resolution.width / this.state.resolution.height

		if (this.state.ratio <= 1 ){
			this.state.format = 'portrait'
		}

		if (this.state.ratio > 1 ){
			this.state.format = 'landscape'
		}

	}	

	getConditionnal(obj){
		let result


		if (this.state.scale == 'mobileWidth'){  // desktop - landscape
			result = obj.mobile
		}		

		if (this.state.scale == 'tabletWidth'){  // desktop - landscape
			result = obj.tablet
		}	
	

		if (this.state.scale == 'deskstopSmall'){  // desktop - landscape
			result = obj.desktopLandscapeSmall
		}


		if (this.state.scale == 'desktopMed'){  // desktop - landscape
			result = obj.desktopLandscapeMed
		}


		if (this.state.scale == 'desktopBig'){  // desktop - landscape
			result = obj.desktopLandscapeBig
		}

		if (this.state.scale == 'desktopMax'){  // desktop - landscape
			result = obj.desktopLandscapeMax
		}

		return result
	}


	getDevice(){
		return this.state.device
	}

	isOldIos(){

		if(isIOS){

			let osVersion = Number(deviceDetect().osVersion.substr(0, 2))

			if ( osVersion < 13 ){
				// console.log('ios version old', deviceDetect())
				return true
			}
			return false
		}
		return false

	}

}


export default {
	init: () => {
		return new Client ()
	}
}

























