// import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

/*__COLORS__________________________*/

const getLightenColor = function(color, percent){
	var num = parseInt(color.replace("#",""),16),
	amt = Math.round(2.55 * percent),
	R = (num >> 16) + amt,
	B = (num >> 8 & 0x00FF) + amt,
	G = (num & 0x0000FF) + amt;
	return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
}

const setColorsActive = function(color){
	color, getLightenColor
	let lightenColor = getLightenColor('#E8E8E8', 1)
	let lighterColor = getLightenColor('#E8E8E8', 50)
	// let variablesObj = { '--color': color, '--color-lighten': lightenColor}
	//#E8E8E8
	//#DDDDDD
	//'#C4C4C4'
	//#242424
	let variablesObj = { '--color': '#DDDDDD', '--color-lighten': lightenColor, '--color-lighter': lighterColor}
	setVars(variablesObj)
}

/*__VARIABLES__________________________*/

const getVar = function (){

}

const setVars = function(variablesObj){
	for (const property in variablesObj) {
		document.documentElement.style.setProperty(property, variablesObj[property])
	}
}

const setMobileVariables = function (){
	// console.log('setMobileVariables')
	let variablesObj = {
		'--home-font-labeur': '16px',
		'--home-font-technical': '30px',

		'--info-font-main': '40px',
		'--info-font-second': '30px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '3vw',
		'--home-esp-bloc-big': '3vw',
		'--home-esp-font-small': '10px',
		'--home-esp-font-big': '14px',

		'--home-round-big': '16px',
		'--home-round-med': '12px',
		'--home-round-small': '8px',

		'--legende-width': '24%',
	}
	setVars(variablesObj)
}

const setTabletVariables = function (){
	// console.log('setTabletVariables')
	let variablesObj = {
		'--home-font-labeur': '16px',
		'--home-font-technical': '16px',

		'--info-font-main': '24px',
		'--info-font-second': '24px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '1vw',
		'--home-esp-bloc-big': '1.5vw',
		'--home-esp-font-small': '10px',
		'--home-esp-font-big': '14px',

		'--home-round-big': '16px',
		'--home-round-med': '12px',
		'--home-round-small': '8px',

		'--legende-width': '32%',
	}
	setVars(variablesObj)
 }

// const setVariables = function (){
// 	console.log('setVariables')
// 	let variablesObj = {

// 	}
// 	setVars(variablesObj)
// }


const setDesktopLandscapeSmall = function (){

	// console.log('setDesktopLandscapeSmall')
	let variablesObj = {
		'--home-font-labeur': '19px',
		'--home-font-technical': '19px',

		'--info-font-main': '35px',
		'--info-font-second': '35px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '1vw',
		'--home-esp-bloc-big': '1.5vw',
		'--home-esp-font-small': '11px',
		'--home-esp-font-big': '13px',

		'--home-round-big': '4px',
		'--home-round-med': '4px',
		'--home-round-small': '4px',

		'--legende-width': '32%',
	}
	setVars(variablesObj)
}

const setDesktopLandscapeMed = function (){

	// console.log('setDesktopLandscapeMed')
	let variablesObj = {
		'--home-font-labeur': '22px',
		'--home-font-technical': '22px',

		'--info-font-main': '45px',
		'--info-font-second': '45px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '1vw',
		'--home-esp-bloc-big': '1.5vw',
		'--home-esp-font-small': '10px',
		'--home-esp-font-big': '14px',

		'--home-round-big': '4px',
		'--home-round-med': '4px',
		'--home-round-small': '4px',

		'--legende-width': '28%',
	}
	setVars(variablesObj)
}

const setDesktopLandscapeBig = function (){

	// console.log('setDesktopLandscapeBig')
	// console.log(window.innerWidth)

	let variablesObj = {
		'--home-font-labeur': '25px',
		'--home-font-technical': '25px',

		'--info-font-main': '53px',
		'--info-font-second': '53px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '1vw',
		'--home-esp-bloc-big': '1.5vw',
		'--home-esp-font-small': '10px',
		'--home-esp-font-big': '14px',

		'--home-round-big': '4px',
		'--home-round-med': '4px',
		'--home-round-small': '4px',

		'--legende-width': '24%',
	}
	setVars(variablesObj)
}


const setDesktopLandscapeMax = function (){

	// console.log('setDesktopLandscapeMax')

	let variablesObj = {
		'--home-font-labeur': '28px',
		'--home-font-technical': '28px',

		'--info-font-main': '60px',
		'--info-font-second': '60px',

		'--home-border-size': '2px',

		'--home-esp-bloc-small': '1vw',
		'--home-esp-bloc-big': '1.5vw',
		'--home-esp-font-small': '10px',
		'--home-esp-font-big': '14px',

		'--home-round-big': '4px',
		'--home-round-med': '4px',
		'--home-round-small': '4px',

		'--legende-width': '24%',
	}
	setVars(variablesObj)

}

/* _________ _________ _________ _________ _________ _________ _________ _________*/

function getRandom(max){
	return Math.floor(Math.random() * Math.floor(max));
}

function getRandom2(min, max){
 return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
	init: () => {
		return { 
			setColorsActive,
			getVar, 
			// setVariables, 
			setTabletVariables, setMobileVariables,
			setDesktopLandscapeSmall, setDesktopLandscapeMed, setDesktopLandscapeBig, setDesktopLandscapeMax,
			getRandom, getRandom2
		}
	}
}






