<template>
  <div id="app">

    <template v-if="this.ClientWidth == 'mobileWidth'">
      <router-view :key="$route.fullPath" name="mobile"></router-view>
    </template>

    <template v-else>
      <router-view :key="$route.fullPath"></router-view>
    </template>

  </div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'App',
  mixins: [clientConfigs],
  data: function(){
    return {
    }
  },
  components: {},
  watch: {

    ClientWidth: {
      immediate: true,
      handler() {
        let params = {
          mobile: this.$tools.setMobileVariables,
          tablet: this.$tools.setTabletVariables,
          desktopLandscapeSmall: this.$tools.setDesktopLandscapeSmall,
          desktopLandscapeMed: this.$tools.setDesktopLandscapeMed,
          desktopLandscapeBig: this.$tools.setDesktopLandscapeBig,
          desktopLandscapeMax: this.$tools.setDesktopLandscapeMax
        }
        this.$client.getConditionnal(params)()
      },
    },

  },
	computed: {},
  methods: {
    setSecurity: function(){
      this.$el.classList.add('visible')

      window.addEventListener('keydown', (e) => {

        if (e.key == "n"){
          this.$el.classList.remove('visible')
        }
      })

    }
  },

	created(){
		this.$client.setListener()
	},
  mounted(){
    // console.log('app')
    // this.setSecurity()
  }
}
</script>

<style lang="scss">

@import '@/css/clean';
@import '@/css/fonts';
@import '@/css/root';
@import '@/css/transition';


#app {
  font-family: 'rglr';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // cursor: var(--cursor-arrow-left);
  cursor: none;

  &.visible{
    opacity: 0;
    pointer-events: none;
  }
}

</style>
