import Vue from 'vue'
import App from './App.vue'
import KirbyApi from '@/plugins/kirby-api'
import Router from './router'
import Datastore from '@/plugins/Datastore'
import Client from '@/plugins/Client'
import Tools from '@/plugins/tools'

Vue.config.productionTip = false

let init_site = async () => {

	// let apiURL = 'https://api.jg123.ovh'
	let apiURL = 'https://api.juliengatard.fr'

	// console.log(apiURL)

	let api = KirbyApi.init(apiURL)
	let dataApi = await api.getSite()
	
	let datastore = Datastore.init()
	datastore.setPage(dataApi)
	let client = Client.init()
	let tools = Tools.init()
	
	let router = await Router.init()

	Vue.prototype.$apiUrl = apiURL
	Vue.prototype.$api = api
	Vue.prototype.$store = datastore
	// Vue.prototype.$site = env_site
	Vue.prototype.$client = client
	Vue.prototype.$tools = tools

	new Vue({
		router,
		render: h => h(App),
		async mounted() {
			setInterval(function(){ document.dispatchEvent(new Event('x-app-rendered')) }, 800);
		},
	}).$mount('#app')

}

init_site()